<template>
    <div class="mine">
		<div class="mine_head">
			
		</div>
		<div class="min_user">
			<div class="mine_img">
				<img :src="userInfo.avatar">
			</div>
			<div class="min_user_name">
				<span>{{userInfo.name}}</span>
			</div>
			<div class="mun_bottonm">
				<div class="mb_left">
					<span>{{userInfo.telphone}}</span>
				</div>
				<div class="mb_right" @click="toEdit">
					<i class="iconfont icon-bianji1"></i>
					<span>编辑</span>
				</div>
			</div>
		</div>
		<div class="min_add" @click="toUserList" v-if="userInfo.studio_role=='host'">
			<div class="minadd_left">
				<div class="ml_icon">
					<i class="iconfont icon-yonghuguanli"></i>
				</div>
				<div class="ml_name">
					<span>用户管理</span>
				</div>
			</div>
			<div class="minadd_right">
				<i class="iconfont icon-gengduo"></i>
			</div>
		</div>
			<footer-main active="/mine"></footer-main>
    </div>
</template>
<script>
import until from '@/api/until.js'
import studio_login_api from '@/api/teaching_studio_no_login.js'
import tool_api from '@/api/teaching_studio_tool.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import footerMain from '@/components/footer.vue'
export default {
	name: 'mine',
	data(){
		return{
			userInfo:{},
		}
	},
	components: {
		footerMain
	},
	mounted(){
		document.title="我的"
		this.getUserInfo();
	},
	methods:{
		//获取当前登录的用户
		async getUserInfo() {
			let app_id = this.$root.app_id;
			let data = await studio_api.Teaching_studio_apiMemberLogin_userGet({ app_id });
			if (data.errcode == 0) {
				this.userInfo = data.other;
				
			}
		},
		toEdit(){
			this.$router.push("/edituser")
		},
		toUserList(){
			this.$router.push("/userlist")
		}

	}
}
</script>
<style scoped>
	.mine{
		width: 100%;
		height: 100vh;
		background: #F6F6F6;
	}
	.mine_head{
		width: 100%;
		height: 200px;
		background: url('/static/imgs/user_head.png');
		background-size:100% 100%; 
	}
	.min_user{
		width: calc(100% - 60px);
		margin-left: 30px;
		margin-top: -60px;
		height: 257px;
		position: relative;
		z-index: 9;
		background: #FFFFFF;
		border-radius: 20px 20px 20px 20px;
		display: inline-block;
	}
	.mine_img{
		position: absolute;
		width: 210px;
		height: 210px;
		background: #FFFFFF;
		border-radius: 50%;
		top: -105px;
		left: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mine_img img{
		width: 200px;
		height: 200px;
		border-radius: 50%;
	}
	.min_user_name{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 32px;
		color: #333333;
		font-weight: bold;
		padding-left: 48px;
		margin-top: 124px;
		box-sizing: border-box;
	}
	.mun_bottonm{
		width: 100%;
		padding: 0 0.64rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16px;
	}
	.mb_left{
		font-size: 28px;
		color: #999999;
		line-height: 40px;
	}
	.mb_right{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 28px;
		color: #4E89FF;
	}
	.mb_right .iconfont{
		font-size: 32px;
		color: #4E89FF;
		margin-right: 16px;
	}
	.min_add{
		width: calc(100% - 60px);
		margin-left: 30px;
		padding: 0 30px;
		box-sizing: border-box;
		height: 120px;
		background: #FFFFFF;
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.minadd_left{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 28px;
		color: #333333;
		font-weight: bold;
		margin-left: 16px;
	}
	.ml_icon .iconfont{
		font-size: 40px;
		color: #4E89FF;
		width: 40px;
		color: 40px;
		margin-right: 10px;
	}
	.minadd_right{
		width: 45px;
		height: 45px;
		background: #E2ECFF;
		opacity: 1;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.minadd_right .iconfont{
		font-size: 24px;
		color: #4E89FF;
	}
</style>
